<template lang="">
  <interview-detail :is-create-form="false" />
</template>
<script>
import InterviewDetail from './components/InterviewDetail.vue'
export default {
  name: 'EditInterview',
  components: { InterviewDetail }
}
</script>
<style lang="">

</style>
